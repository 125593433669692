import moment from 'moment';
import { defineStore } from 'pinia';
import { set } from 'vue';
import { AuthResponse } from '@/models/authResponse';
import { makeRequest } from '@/services//api-request';
import { Group } from '@/models/group';
import { GroupMembers } from '@/models/groupMembers';
import { SlotTeachers } from '@/models/slotTeachers';

export const useSessionsStore = defineStore('sessions', {
  state: () => ({
    sessions: [] as Array<Group>,
    session: null as Group | null,
    groupMembers: {} as { [groupId: string]: GroupMembers },
    date: moment().format('YYYY-MM-DD'),
    slotInfo: {} as { [slotId: number]: SlotTeachers },
  }),
  actions: {
    findSession(groupId: number) {
      return this.sessions.find((s: Group) => s.id === groupId) || null;
    },
    async getSession(groupId: number) {
      if (!this.session || this.session.id !== groupId) {
        this.session = this.findSession(groupId);
      }
      if (!this.session) {
        const authResponse: AuthResponse = await makeRequest('GET', `/groups/${groupId}`);
        this.session = authResponse.body as Group;
      }
    },
    async getSessionGroups() {
      const queryParams: Record<string, string> = {
        start_at_gt: this.date,
        sort: 'astart_at',
        include_deleted: '1',
      };
      const authResponse: AuthResponse = await makeRequest('GET', `/groups?${new URLSearchParams(queryParams)}`);
      this.sessions = authResponse.body;
    },
    groupMembersLoaded(groupId: number) {
      return groupId in this.groupMembers;
    },
    async getGroupMembers(groupId: number, force: boolean) {
      const session = this.findSession(groupId);
      if (this.groupMembersLoaded(groupId) && !force) {
        // Don't load if already loaded
        return;
      }

      // If there size is 0, don't load unless forced -- ie, if someone has just been added/removed and we need to refresh
      if (session && (force || session.total_members > 0)) {
        const result: Array<AuthResponse> = await Promise.all([
          makeRequest('GET', `/groups/${groupId}/groups`),
          makeRequest('GET', `/groups/${groupId}/members?without_child_groups=1`),
        ]);

        const size = result[0].body.total_members;
        session.total_members = size;
        set(this.groupMembers, groupId, { groups: result[0].body.groups, members: result[1].body });
      } else {
        set(this.groupMembers, groupId, { groups: [], members: [] });
      }
    },
    async getSlotInfo(slotId: number) {
      if (this.slotInfo[slotId] && this.slotInfo[slotId].data) {
        return;
      }
      set(this.slotInfo, slotId, { data: [] });
      const authResponse: AuthResponse = await makeRequest('GET', '/slots/' + slotId);
      set(this.slotInfo[slotId], 'data', authResponse.body.content);
    },
  },
});
