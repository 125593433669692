import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import auth from '@/store/modules/auth';
import clubs from '@/store/modules/clubs';
import groupMembers from '@/store/modules/groupMembers';
import groups from '@/store/modules/groups';
import members from '@/store/modules/members';
import nav from '@/store/modules/nav';
import schemes from '@/store/modules/schemes';
import users from '@/store/modules/users';
import { state, mutations, getters, IState } from './root';
import { useCoachesStore } from '@/stores/coaches';
import { useSessionsStore } from '@/stores/sessions';
import { useMemberFiltersStore } from '@/stores/member-filters';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions: {
    resetState: async (context: ActionContext<IState, IState>) => {
      useCoachesStore().$reset();
      useSessionsStore().$reset();
      useMemberFiltersStore().$reset();
      return Promise.all([
        context.dispatch('auth/resetState'),
        context.dispatch('clubs/resetState'),
        context.dispatch('groupMembers/resetState'),
        context.dispatch('groups/resetState'),
        context.dispatch('members/resetState'),
        context.dispatch('nav/resetState'),
        context.dispatch('schemes/resetState'),
        context.dispatch('users/resetState'),
      ]);
    },
  },
  modules: {
    auth,
    clubs,
    groupMembers,
    groups,
    members,
    nav,
    schemes,
    users,
  },
  getters,
});
