import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "club-login" */ '../views/common/LoginPage.vue'),
  },
  {
    path: '/home',
    // route level code-splitting
    // this generates a separate chunk (scheme.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "club-home" */ '@/views/club/Home.vue'),
    children: [
      {
        path: '',
        name: 'members',
        // route level code-splitting
        // this generates a separate chunk (scheme.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "club-members" */ '@/components/club/Members.vue'),
      },
      {
        path: '/sessions',
        name: 'sessions',
        // route level code-splitting
        // this generates a separate chunk (scheme.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "club-sessions" */ '@/components/club/Sessions.vue'),
      },
      {
        path: '/sessions/:groupId',
        name: 'session-members',
        // route level code-splitting
        // this generates a separate chunk (scheme.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "club-session-members" */ '@/components/club/groups/GroupMembers.vue'),
        props: route => route.params,
      },
      {
        path: '/coaches',
        name: 'coaches',
        // route level code-splitting
        // this generates a separate chunk (scheme.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "club-coaches" */ '@/components/club/Coaches.vue'),
      },
      {
        path: '/member-groups',
        // route level code-splitting
        // this generates a separate chunk (scheme.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "club-groupsview" */ '@/components/club/groups/GroupsView.vue'),
        children: [
          {
            path: '',
            name: 'groups',
            component: () => import(/* webpackChunkName: "club-groups" */ '@/components/club/groups/Groups.vue'),
          },
          {
            path: ':groupId',
            name: 'groupMembers',
            component: () =>
              import(/* webpackChunkName: "club-groupmembers" */ '@/components/club/groups/GroupMembers.vue'),
            props: route => route.params,
          },
        ],
      },
      {
        path: '/reports/:report?/:schemeSlug?',
        name: 'reports',
        component: () => import(/* webpackChunkName: "club-reports" */ '@/components/club/Reports.vue'),
        props: route => ({ report: route.params.report, schemeSlug: route.params.schemeSlug }),
      },
      {
        path: '/awards-email',
        name: 'awards-email',
        // route level code-splitting
        // this generates a separate chunk (scheme.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "club-awards-email" */ '@/components/club/Awards-Email.vue'),
      },
    ],
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "club-users" */ '../views/common/UsersPage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "club-about" */ '../views/club/AboutClub.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "club-redirect" */ '@/components/common/PostOAuthRedirect.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "common-logout" */ '../views/common/LogoutPage.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "common-error" */ '@/views/common/ErrorPage.vue'),
  },
  {
    path: '*',
    redirect: '/error',
  },
];

export default routes;
